import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
//image
import imgAccueil from '../images/communes.png';

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: '87vh',
    },

    image: {
        backgroundImage: "url(" + imgAccueil + ")",
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            'grey',
        backgroundSize: 'center',
        backgroundPosition: 'center',
    },
}));

const Accueil = () => {
    const classes = useStyles();

    return (
        <Grid container component="main" className={classes.root}>
            <Grid item xs={false} sm={12} md={12} className={classes.image} />
        </Grid>
    )
}

export default Accueil;