//navbar - toolbar
export const navbarBackgroundColor = "#757474";
export const toolbarBackgroundColor = "#8dc549"; 
//title logo  
export const colorTextTitle = '#8dc549';
/* corners css are in app.css */

//table toolbar
export const colorTextHeader = '#8dc549';
export const backGroundHeader = "#FFF";

//table headerStyle
export const tableHeaderBackgroundColor = '#757474';
export const tableHeaderColor = 'white';

//table row style
export const tableRowBackgroundColor = 'white';

//tab button add
export const tabButtonAddRow = "#8dc549" ;

//table text color

export const tableTextColor = '#757474';